import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import ScrollAnimation from './directives/scrollanimation'
import './assets/css/style.css'
import './assets/css/slick.css'
import './assets/css/slick-theme.css'
import store from './store/index';
import VueGtag from 'vue-gtag'
import VueCountryCode from "vue-country-code";


Vue.directive('scrollanimation', ScrollAnimation);

Vue.config.productionTip = false
Vue.use(VueCountryCode);
Vue.use(VueGtag, {
    config: {
        id: 'G-TN87H646EC',
        params: {
            send_page_view: false
        }
    }
}, router)


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')